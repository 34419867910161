import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { SessionAuth } from './session-auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private sessionAuth: SessionAuth;

  constructor(private http: HttpClient) { }

  init(): Observable<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');
    const secureCodeHash = urlParams.get('secureCodeHash');
    const mobileSdkVersion = urlParams.get('kycMobileSdkVersion');
    const kycClientPlatform = urlParams.get('kycClientPlatform');
    const kycClientTheme = urlParams.get('kycClientTheme');

    if (sessionId && secureCodeHash) {
      return this.http.get<SessionAuth>('api/kyc/v1/session/authorization', { params: { secureCodeHash, sessionId } })
        .pipe(map(response => {
          this.sessionAuth = response;
          this.sessionAuth.mobileSdkVersion = mobileSdkVersion;
          this.sessionAuth.clientPlatform = kycClientPlatform;
          this.sessionAuth.clientTheme = kycClientTheme;
          return null;
        }));
    }

    return of(null);
  }

  getXsrfToken(): string {
    return this.sessionAuth?.xsrfToken;
  }

  getAccessToken(): string {
    return this.sessionAuth?.accessToken;
  }

  getMerchantName(): string {
    return this.sessionAuth?.merchantName;
  }

  getRedirectUrl(): string {
    return this.sessionAuth?.redirectUrl;
  }

  getClientPlatform(): string {
    return this.sessionAuth?.clientPlatform;
  }

  getClientTheme(): string {
    return this.sessionAuth?.clientTheme;
  }

  getMobileSdkVersion(): string {
    return this.sessionAuth?.mobileSdkVersion;
  }
}

